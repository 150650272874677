import"core-js/modules/es.array.push.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/web.dom-collections.for-each.js";import{importEntities}from'soapbox/entity-store/actions';import{Entities}from'soapbox/entity-store/entities';import{accountSchema,groupSchema}from'soapbox/schemas';import{filteredArray}from'soapbox/schemas/utils';import{getSettings}from'../settings';var ACCOUNT_IMPORT='ACCOUNT_IMPORT';var ACCOUNTS_IMPORT='ACCOUNTS_IMPORT';var GROUP_IMPORT='GROUP_IMPORT';var GROUPS_IMPORT='GROUPS_IMPORT';var STATUS_IMPORT='STATUS_IMPORT';var STATUSES_IMPORT='STATUSES_IMPORT';var POLLS_IMPORT='POLLS_IMPORT';var ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP='ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP';var importAccount=function importAccount(data){return function(dispatch,_getState){dispatch({type:ACCOUNT_IMPORT,account:data});try{var account=accountSchema.parse(data);dispatch(importEntities([account],Entities.ACCOUNTS));}catch(e){//
}};};var importAccounts=function importAccounts(data){return function(dispatch,_getState){dispatch({type:ACCOUNTS_IMPORT,accounts:data});try{var accounts=filteredArray(accountSchema).parse(data);dispatch(importEntities(accounts,Entities.ACCOUNTS));}catch(e){//
}};};var importGroup=function importGroup(group){return importEntities([group],Entities.GROUPS);};var importGroups=function importGroups(groups){return importEntities(groups,Entities.GROUPS);};var importStatus=function importStatus(status,idempotencyKey){return function(dispatch,getState){var expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUS_IMPORT,status:status,idempotencyKey:idempotencyKey,expandSpoilers:expandSpoilers});};};var importStatuses=function importStatuses(statuses){return function(dispatch,getState){var expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUSES_IMPORT,statuses:statuses,expandSpoilers:expandSpoilers});};};var importPolls=function importPolls(polls){return{type:POLLS_IMPORT,polls:polls};};var importFetchedAccount=function importFetchedAccount(account){return importFetchedAccounts([account]);};var importFetchedAccounts=function importFetchedAccounts(accounts){var args=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{should_refetch:false};var should_refetch=args.should_refetch;var normalAccounts=[];var processAccount=function processAccount(account){if(!account.id)return;if(should_refetch){account.should_refetch=true;}normalAccounts.push(account);if(account.moved){processAccount(account.moved);}};accounts.forEach(processAccount);return importAccounts(normalAccounts);};var importFetchedGroup=function importFetchedGroup(group){return importFetchedGroups([group]);};var importFetchedGroups=function importFetchedGroups(groups){var entities=filteredArray(groupSchema).parse(groups);return importGroups(entities);};var importFetchedStatus=function importFetchedStatus(status,idempotencyKey){return function(dispatch){var _status$reblog,_status$quote,_status$pleroma,_status$pleroma$quote,_status$reblog2,_status$reblog2$quote,_status$reblog3,_status$reblog3$plero,_status$reblog3$plero2,_status$poll,_status$group;// Skip broken statuses
if(isBroken(status))return;if((_status$reblog=status.reblog)!==null&&_status$reblog!==void 0&&_status$reblog.id){dispatch(importFetchedStatus(status.reblog));}// Fedibird quotes
if((_status$quote=status.quote)!==null&&_status$quote!==void 0&&_status$quote.id){dispatch(importFetchedStatus(status.quote));}// Pleroma quotes
if((_status$pleroma=status.pleroma)!==null&&_status$pleroma!==void 0&&(_status$pleroma$quote=_status$pleroma.quote)!==null&&_status$pleroma$quote!==void 0&&_status$pleroma$quote.id){dispatch(importFetchedStatus(status.pleroma.quote));}// Fedibird quote from reblog
if((_status$reblog2=status.reblog)!==null&&_status$reblog2!==void 0&&(_status$reblog2$quote=_status$reblog2.quote)!==null&&_status$reblog2$quote!==void 0&&_status$reblog2$quote.id){dispatch(importFetchedStatus(status.reblog.quote));}// Pleroma quote from reblog
if((_status$reblog3=status.reblog)!==null&&_status$reblog3!==void 0&&(_status$reblog3$plero=_status$reblog3.pleroma)!==null&&_status$reblog3$plero!==void 0&&(_status$reblog3$plero2=_status$reblog3$plero.quote)!==null&&_status$reblog3$plero2!==void 0&&_status$reblog3$plero2.id){dispatch(importFetchedStatus(status.reblog.pleroma.quote));}if((_status$poll=status.poll)!==null&&_status$poll!==void 0&&_status$poll.id){dispatch(importFetchedPoll(status.poll));}if((_status$group=status.group)!==null&&_status$group!==void 0&&_status$group.id){dispatch(importFetchedGroup(status.group));}dispatch(importFetchedAccount(status.account));dispatch(importStatus(status,idempotencyKey));};};// Sometimes Pleroma can return an empty account,
// or a repost can appear of a deleted account. Skip these statuses.
var isBroken=function isBroken(status){try{// Skip empty accounts
// https://gitlab.com/soapbox-pub/soapbox/-/issues/424
if(!status.account.id)return true;// Skip broken reposts
// https://gitlab.com/soapbox-pub/rebased/-/issues/28
if(status.reblog&&!status.reblog.account.id)return true;return false;}catch(e){return true;}};var importFetchedStatuses=function importFetchedStatuses(statuses){return function(dispatch,getState){var accounts=[];var normalStatuses=[];var polls=[];function processStatus(status){var _status$reblog4,_status$quote2,_status$pleroma2,_status$pleroma2$quot,_status$poll2,_status$group2;// Skip broken statuses
if(isBroken(status))return;normalStatuses.push(status);accounts.push(status.account);if((_status$reblog4=status.reblog)!==null&&_status$reblog4!==void 0&&_status$reblog4.id){processStatus(status.reblog);}// Fedibird quotes
if((_status$quote2=status.quote)!==null&&_status$quote2!==void 0&&_status$quote2.id){processStatus(status.quote);}if((_status$pleroma2=status.pleroma)!==null&&_status$pleroma2!==void 0&&(_status$pleroma2$quot=_status$pleroma2.quote)!==null&&_status$pleroma2$quot!==void 0&&_status$pleroma2$quot.id){processStatus(status.pleroma.quote);}if((_status$poll2=status.poll)!==null&&_status$poll2!==void 0&&_status$poll2.id){polls.push(status.poll);}if((_status$group2=status.group)!==null&&_status$group2!==void 0&&_status$group2.id){dispatch(importFetchedGroup(status.group));}}statuses.forEach(processStatus);dispatch(importPolls(polls));dispatch(importFetchedAccounts(accounts));dispatch(importStatuses(normalStatuses));};};var importFetchedPoll=function importFetchedPoll(poll){return function(dispatch){dispatch(importPolls([poll]));};};var importErrorWhileFetchingAccountByUsername=function importErrorWhileFetchingAccountByUsername(username){return{type:ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP,username:username};};export{ACCOUNT_IMPORT,ACCOUNTS_IMPORT,GROUP_IMPORT,GROUPS_IMPORT,STATUS_IMPORT,STATUSES_IMPORT,POLLS_IMPORT,ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP,importAccount,importAccounts,importGroup,importGroups,importStatus,importStatuses,importPolls,importFetchedAccount,importFetchedAccounts,importFetchedGroup,importFetchedGroups,importFetchedStatus,importFetchedStatuses,importFetchedPoll,importErrorWhileFetchingAccountByUsername};