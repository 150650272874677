/**
 * Notification normalizer:
 * Converts API notifications into our internal format.
 * @see {@link https://docs.joinmastodon.org/entities/notification/}
 */import{Map as ImmutableMap,Record as ImmutableRecord,fromJS}from'immutable';// https://docs.joinmastodon.org/entities/notification/
export var NotificationRecord=ImmutableRecord({account:null,chat_message:null,// pleroma:chat_mention
created_at:new Date(),emoji:null,// pleroma:emoji_reaction
emoji_url:null,// pleroma:emoji_reaction
id:'',status:null,target:null,// move
type:'',total_count:null// grouped notifications
});var normalizeType=function normalizeType(notification){if(notification.get('type')==='group_mention'){return notification.set('type','mention');}return notification;};export var normalizeNotification=function normalizeNotification(notification){return NotificationRecord(ImmutableMap(fromJS(notification)).withMutations(function(notification){normalizeType(notification);}));};